import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import dateFormat from "dateformat";
import { useLocation } from "@reach/router";

import { SaveItem } from "@starberry/myaccount-website-utils"

import { propertyDetailUrl, GetPrice, PropertySearchUrl } from "gatsby-theme-starberry-lomondgroup/src/common/utils/property_utils";
import { ShowProcessedImage } from 'gatsby-theme-starberry-lomondgroup/src/common/ggfx-client/module/components/show-image';
import imageConfig from "../../../../static/images/config.json"
import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module";
import ContentModule from "gatsby-theme-starberry-lomondgroup/src/modules/content_module";
import SearchResultsImagePopup from "gatsby-theme-starberry-lomondgroup/src/components/SalesCard/SearchResultsImagePopup"
import { HeartIcon } from "gatsby-theme-starberry-lomondgroup/src/common/icons";

const ListView = ({ item, img_type, isGridView, userObjects, collections_property, list_type, checked, handleCheck, show_pppw, setBulkEnquiry, add_enquiry}) => {

    const location = useLocation();

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [show_tooltip, setShowTooltip] = useState(false);
    const [tooltip_text, setTooltipTexT] = useState('');

    const whislist_item = typeof window !== 'undefined' && (localStorage.getItem('whislist_data') ? JSON.parse(localStorage.getItem('whislist_data')) : "");

    //console.log("add_enquiry", add_enquiry)

    const addEnquiry = () => {
        var property_details = {
                "id":item.objectID || item.id, 
                "crm_id":item.crm_id, 
                "title":item.title, 
                "department":item.department,
                "images":item.images,
                "imagetransforms":item.imagetransforms,
                "extra":item.extra,
                "office_crm_id":item.office_crm_id,
                "search_type":item.search_type,
                "status":item.status,
                "slug":item.slug,
                "officeDepartment":item.officeDepartment,
                "price_qualifier":item.price_qualifier,
                "price":item.price,
                "display_address":item.display_address,
                "bedroom":item.bedroom,
                "bathroom":item.bathroom,
                "council_tax":item.council_tax,
                "available_from":item.available_from,
                "description":item.description
            }

        setBulkEnquiry([...add_enquiry, property_details])
        setTooltipTexT("Added to Bulk Enquiry")
        setShowTooltip(true);
        setTimeout(()=>{
            setShowTooltip(false);
        },[1000])
    }

    const removeEnquiry = () => {
        var property_id = item.objectID || item.id;
        var remove_property = add_enquiry?.length > 0 && add_enquiry.filter(property => property.id !== property_id)
        setBulkEnquiry(remove_property)
        setTooltipTexT("Removed from Bulk Enquiry")
        setShowTooltip(true);
        setTimeout(()=>{
            setShowTooltip(false);
        },[1000])
    }

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }

    var department = item?.department ? item.department : "residential"
 
    var property_id = item.objectID || item.id;

    var property_url = propertyDetailUrl(department, item.search_type, item.status, item.slug, property_id);

    //var search_url = PropertySearchUrl(item.department, item.search_type);
    var search_url = list_type === "whislist" ? "" : location.pathname;

    var img_transforms = imageConfig.property.images.list_view.sizes;
    var thumb_transforms = imageConfig.property.images.thumnail_image.sizes;

    var alt_text = item.display_address + " - " + process.env.GATSBY_SITE_NAME;


    const ImageRenderList = ({ item, image_url, imagename }) => {
        let processedImages = JSON.stringify({});
        if (item?.imagetransforms?.images_Transforms) {
            processedImages = item?.imagetransforms?.images_Transforms;
        }
        return (
            <ImageModule ImageSrc={image_url} title={item.display_address} altText={item.display_address} imagetransforms={processedImages} renderer="srcSet" imagename={imagename} strapi_id={item.id} />
        )
    }

    var energyRate = item?.energyRate ? item?.energyRate : item?.extra?.energyRate !== "" ? item?.extra?.energyRate : ""

    var prop_images = item?.images?.filter(img => img?.url && img.url !== null)

    var office_depart = item?.office_crm_id ? item?.office_crm_id : "";

    //console.log("images ===>", prop_images);

    var pppw = GetPrice(item.price)+"/PCM";

    if(show_pppw){
        pppw = item.price > 0 && parseInt(item.bedroom) > 0 ? GetPrice(item.price*12/52/item.bedroom)+"/PPPW" : GetPrice(item.price)+"/PM";
    }


    var bulk_enquiry_status = add_enquiry?.length > 0 && add_enquiry.filter(property => property.id === property_id);

    //console.log("bulk_enquiry_status", bulk_enquiry_status, property_id)

    return (
        <div className="sales-wrap" id={property_id}>
            <div className="sales-img-wrap">
                <div className={`sales-img img-zoom ${item?.images?.length > 1 ? "" : "full_wdth"}`}>

                    {list_type === "whislist" && 
                        <div className="include_bills d-lg-block d-none">
                            <label class="container-wrap">
                                <input type="checkbox" name="whislist_property" value={property_id} checked={checked} onChange={(event) => handleCheck(event, item)} />
                                <span class="checkmark"></span> 
                            </label>
                        </div>
                    }
                    {/* <img src={item.images1[0]["570x374"]} alt="img" /> */}
                    <Link to={property_url} state={{ search_url }}>
                        {img_type === "no_property" ?
                            <ImageRenderList item={item} image_url={prop_images[0]}  />
                            :
                            item?.images ? (
                                item?.images.length > 0 ?
                                    <ShowProcessedImage images={item?.images[0]} attr={{ alt: alt_text }} transforms={img_transforms} />
                                :
                                    <ImageRenderList item={item} image_url={item?.images[0]} imagename={"property.images.list_view"} />
                                )
                            :
                                <ImageRenderList item={item} image_url={prop_images[0]}  />
                        }
                    </Link>


                    {(item.status !== "For Sale" && item.status !== "To Let") ? 
                        (<span className={`top-text`}>
                            {item.status}
                        </span>
                        )
                    : ''
                    }
                    

                    {item?.images.length > 0 &&
                        <span className="bottom-text" onClick={(e) => openLighboxMobile(e, 0)}>
                            <i className="gallery-icon"></i>
                            <span className="text">{item?.images.length}</span>
                        </span>
                    }

                    <SaveItem type="property" pid={item.objectID || item.id} userObjects={userObjects}>
                        <span className="save-item">
                            <HeartIcon /><span> Save</span><span className="saved-text">d</span>
                        </span>
                    </SaveItem>
                </div>

                {/* ======= List View Thumbnail Image ===== */}
                {item?.images.length > 1 &&
                    <div className="sm-sales-img-wrap d-none d-xl-block">
                        {item?.images.map((img, i) => {
                            if (i > 0) {
                                return (
                                    <a href="javascript:;" onClick={(e) => openLighboxMobile(e, i)}>

                                        {img_type === "no_property" ?
                                            (i <= 3 &&
                                                <ImageRenderList item={item} image_url={prop_images[i]} />
                                            )
                                            :
                                            <ShowProcessedImage images={item?.images[i]} attr={{ alt: alt_text }} transforms={thumb_transforms} />
                                        }

                                    </a>
                                )
                            }

                        })}
                    </div>
                }

                {isOpen && (item?.images && item?.images?.length != 0) && (
                    <SearchResultsImagePopup
                        propertyId={item.objectID || item.id}
                        propertyImage={item?.images}
                        photoIndex={photoIndex}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        largeSize="720x380"
                        largeSize2="570x374"
                        img_type={img_type}
                    />
                )}
            </div>

            <div className="slide-content">

                {/* <div className={`whislist_icon ${bulk_enquiry_status?.length > 0 ? "has_bulk_enq" : ""}`}>
                    {bulk_enquiry_status?.length > 0 ?
                        <i className="icon-hearteen" onClick={removeEnquiry}></i>
                        :
                        <i className="icon-hearteen" onClick={addEnquiry}></i>
                    }
                    {show_tooltip && <span className="tool_tip_enquiry">{tooltip_text}</span>}
                </div>      */}

                <div className="h5">
                    <Link to={property_url} state={{ search_url }}>{item.title}</Link>               
                </div>
                <div className="content">
                    <p className="highlight-text">{GetPrice(item.price)}
                        <span className="sm-text ">{parseInt(item?.bedroom) > 1 ? "pppw" : "pw"}</span> 
                        {/* {(item?.search_type === "lettings") ? 
                            <span className="sm-text ">{item.price_qualifier}</span> 
                        : ''
                        }  */}
                    </p>

                </div>

                <div className="d-md-flex title-wrap">
                    <h3><Link to={property_url} state={{ search_url }}>{item.display_address}</Link></h3>
                </div>

                <div className="icon-wrapper">
                    {parseInt(item.bedroom) > 0 ?
                        <div className="icon-wrap">
                            <a href="javascript:;" title="Bedroom">
                                <i className="icon-bed"></i>
                            </a>
                            <span className="count">{item.bedroom}</span>
                        </div>
                        :""
                    }

                    {parseInt(item.bathroom) > 0 ?
                        <div className="icon-wrap">
                            <a href="javascript:;" title="Bathroom">
                                <i className="icon-bath"></i>
                            </a>
                            <span className="count">{item.bathroom}</span>
                        </div>
                        :""
                    }

                    {/* {item.hasParking === true &&
                        <div className="icon-wrap">
                            <a href="javascript:;">
                                <i className="icon-parking"></i>
                            </a>
                            <span className="count">Parking</span>
                        </div>
                    } */}

                    {/* {item.council_tax ?
                        <div className="icon-wrap">
                            <a href="javascript:;" title="Council Tax">
                                <i className="icon-house"></i>
                            </a>
                            <span className="count">{item.council_tax}</span>
                        </div>
                        :""
                    }

                    {energyRate ?
                        <div className="icon-wrap">
                            <a href="javascript:;" title="Energy Rating">
                                <i className="icon-light"></i>
                            </a>
                            <span className="count">{energyRate}</span>
                        </div>
                        :""
                    } */}

                    {/* <div className="icon-wrap laundry">
                        <a href="javascript:;" title="Laundry Facility">
                            <i className="icon-laundry"></i>
                        </a>
                        <span className="count">Laundry Facility</span>
                    </div> */}
                </div>
                {item.search_type === "lettings" && 
                    (!isGridView ? 
                        (item.available_from && 
                            <div className="available_date">
                                {new Date(item.available_from).valueOf() > new Date().valueOf() 
                                    ? `Available from ${dateFormat(item.available_from, "dd/mm/yyyy")}`
                                    : `Available Now`
                                }
                            </div>
                        )

                    :
                        <div className="available_date">
                            {item.available_from ? 
                                (new Date(item.available_from).valueOf() > new Date().valueOf() 
                                ? `Available from ${dateFormat(item.available_from, "dd/mm/yyyy")}`
                                : `Available Now`
                                )
                                : ''
                            }
                        </div>
                    )
                }

                <p className="d-none d-md-block short_desc">
                    <span>{item?.description?.replace(/<br\s*\/?>/gi, ' ')}</span>
                    {/* {item?.description && <ContentModule Content={item?.description} />} */}

                    <Link to={property_url} className="more-link" state={{ search_url }}>more</Link>
                </p>

            </div>
        </div>
    );
};
export default ListView;
